@import "~antd/dist/antd.less";

@font-face {
  font-family: "Avenir-Roman";
  src: local("Avenir-Roman"),
    url("./assets/fonts/Avenir-Roman.woff") format("woff");
}
@font-face {
  font-family: "Gilroy";
  src: url("./assets/fonts/Gilroy800.ttf") format("truetype");
}

:root {
  --transparent-gray: #00000050;
  --gray: #4f5655;
  --mid-gray: #cdcfce;
  --light-gray: #f5f5f5;
  --gray-text: #686e6d;
  --v-light-green: #f0f8f6;
  --light-green: #c9e4df;
  --green: #0b7764;

  --top-bottom-padding: 25%;
  --left-right-padding: 15%;
  --clip-gap-top: calc(var(--top-bottom-padding) - 0.7rem);
  --clip-gap-left: calc(var(--left-right-padding) - 0.7rem);
  --container-max-width: 450px;
}

html {
  box-sizing: border-box; // so width will be calculated with padding and border
  // padding-right: 1.2rem; // escape scroll-bar width
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0 auto;
  max-width: 600px;
  font-family: "Avenir-Roman";
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  .login-page {
    display: flex;
    // background: ;
    background: url("./assets/images/login-bg.svg") left 30px bottom,
      radial-gradient(220% 130% at top right, white 70%, #f7fde6 30.1%);
    background-repeat: no-repeat;
    flex-direction: column;
    height: 100vh;
    padding: 1rem;
    .page-title {
      display: flex;
      align-items: center;
      gap: 5px;
      img {
        width: 1.5rem;
        height: 1.5rem;
      }
      h2 {
        margin: 0;
        padding: 0;
        font-family: "Gilroy";
        font-size: 1.5rem;
      }
    }

    .login-form {
      margin-top: 14vh;
      padding: 1rem;

      #signIn {
        margin-top: 1.5rem;
      }

      .login-form-button {
        width: 100%;
        border-radius: 4px;
      }
      .login-footer {
        text-align: center;

        a {
          font-weight: bold;
        }
      }
    }
  }

  .logout-button {
    display: flex;
    gap: 4px;
    align-items: center;
    color: var(--gray);
    cursor: pointer;
    justify-content: center;
  }

  .home {
    display: flex;
    flex-direction: column;

    .header-image {
      img {
        width: 100%;
      }
    }
    .home-container {
      --container-padding: 1.5rem;
      --org-details-height: 3rem;
      padding: 0 var(--container-padding);
      max-width: var(--container-max-width);
      width: 100%;
      margin: 0 auto;
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      z-index: 1000;
      .org-details {
        // external
        margin: 0 calc(var(--container-padding) / -3);
        margin-top: calc(var(--org-details-height) / -2);
        margin-bottom: 0.1rem;
        height: var(--org-details-height);

        // internal -> layout
        display: flex;
        align-items: center;

        // internal -> style
        background-color: white;
        border-radius: 8px;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.06);
        .org-name {
          // font-family: "Gilroy";
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          font-weight: 600;
          font-size: 1rem;
          padding: 0 0.8rem;
        }
        img {
          height: 100%;
        }
      }

      .home-card {
        border-radius: 4px;
        display: flex;
        margin: 0.5rem 0;
        padding: 1.3rem 1rem;

        .home-card__title {
          font-weight: 600;
        }
        &.total-buyers {
          background: var(--light-gray);
          justify-content: space-between;

          .buyers-count {
            font-weight: 600;
          }
        }
        &.current-buyer {
          background: var(--v-light-green);
          flex-direction: column;
          gap: 1rem;

          .card-body-buyer-details {
            display: flex;
            flex-direction: column;
            color: var(--gray);
            gap: 0.5rem;
          }

          .scan-more-btn {
            align-self: center;
            background-color: var(--green);
            border-color: var(--green);

            display: flex;
            gap: 5px;
            align-items: center;
            border-radius: 4px;
            padding: 1.1rem 1.4rem;
          }
        }
      }
      .home-qr-scanner {
        margin: auto 0;
        margin-bottom: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .home-qr-scanner-info {
          text-align: center;
          padding: 1rem 0;
          color: var(--gray-text);
        }
        .home-scan-button {
          align-self: center;
          padding: 0.7rem 2rem;
          box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.24);
          height: unset;
        }
      }
    }
    .home-footer {
      margin: 2rem 0;
    }
  }

  .qr-page {
    position: relative;

    .qr-background > * {
      position: absolute;
      z-index: 4;

      &:nth-child(1) {
        top: var(--clip-gap-top);
        left: var(--clip-gap-left);
      }
      &:nth-child(2) {
        top: var(--clip-gap-top);
        right: var(--clip-gap-left);
        transform: rotate(90deg);
      }
      &:nth-child(3) {
        bottom: var(--clip-gap-top);
        left: var(--clip-gap-left);
        transform: rotate(-90deg);
      }
      &:nth-child(4) {
        bottom: var(--clip-gap-top);
        right: var(--clip-gap-left);
        transform: rotate(180deg);
      }
    }
    .qr-overlay {
      position: absolute;
      z-index: 3;
      display: flex;
      flex-direction: column;
      width: 100%;
      color: white;
      height: calc(var(--top-bottom-padding) * 0.8);
      // justify-content: space-around;

      .qr-title {
        align-self: center;
        font-weight: 600;
        font-size: 1rem;
        margin: auto 0;
      }

      .qr-close {
        align-self: flex-end;
        padding: 1rem;
        cursor: pointer;
        line-height: 0;
      }
    }
    .qr-section {
      position: relative;

      &::before {
        content: "";
        display: block;
        position: absolute;
        inset: 0;
        z-index: 1;

        // -webkit-clip-path: inset(var(--top-bottom-padding) var(--left-right-padding) round 20px);
        // clip-path: inset(var(--top-bottom-padding) var(--left-right-padding) round 20px);
        -webkit-clip-path: polygon(
          0% 0%,
          0% 100%,
          var(--left-right-padding) 100%,
          var(--left-right-padding) var(--top-bottom-padding),
          calc(100% - var(--left-right-padding)) var(--top-bottom-padding),
          calc(100% - var(--left-right-padding))
            calc(100% - var(--top-bottom-padding)),
          var(--left-right-padding) calc(100% - var(--top-bottom-padding)),
          var(--left-right-padding) 100%,
          100% 100%,
          100% 0%
        );
        clip-path: polygon(
          0% 0%,
          0% 100%,
          var(--left-right-padding) 100%,
          var(--left-right-padding) var(--top-bottom-padding),
          calc(100% - var(--left-right-padding)) var(--top-bottom-padding),
          calc(100% - var(--left-right-padding))
            calc(100% - var(--top-bottom-padding)),
          var(--left-right-padding) calc(100% - var(--top-bottom-padding)),
          var(--left-right-padding) 100%,
          100% 100%,
          100% 0%
        );

        background: var(--transparent-gray);

        // background: url("./assets/images/clip-path/clip-path-1.svg") top
        //     var(--top-bottom-padding) left var(--left-right-padding),
        //   url("./assets/images/clip-path/clip-path-2.svg") top
        //     var(--top-bottom-padding) right var(--left-right-padding),
        //   url("./assets/images/clip-path/clip-path-3.svg") bottom
        //     var(--top-bottom-padding) left var(--left-right-padding),
        //   url("./assets/images/clip-path/clip-path-4.svg") bottom
        //     var(--top-bottom-padding) right var(--left-right-padding),
        //   #00000050;

        background-repeat: no-repeat;
      }
    }
  }

  .profile-details {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 0.7rem;
    margin-top: 2rem;
    padding: 0 1.5rem;

    .profile-fields {
      p {
        font-weight: 600;
        font-size: 1rem;
      }
    }

    .logout-button-container {
      margin-top: auto;
      padding: 1.5rem 0;
    }
  }
}

.product-view {
  .ant-drawer-content {
    padding-top: 0.7rem;
    border-radius: 2rem 2rem 0 0;
  }

  .ant-drawer-header-title {
    // flex order reverse
    flex-direction: row-reverse;

    .ant-drawer-title {
      font-size: 1.1rem;
      font-weight: 600;
    }
    .ant-drawer-close {
      margin: 0;
    }
  }

  .ant-carousel {
    .product-image-container {
      display: flex !important;
      height: 300px;
      border-radius: 8px;
      background: var(--light-gray);
      .product-image {
        width: 100%;
        height: 300px;
        img {
          object-fit: contain;
          width: 100%;
          height: 100%;
        }
      }
    }

    .slick-dots li {
      button {
        width: 7px;
        height: 7px;
        border-radius: 100%;
        background: var(--mid-gray);
      }
      &.slick-active button {
        width: 10px;
        height: 10px;
        border-radius: 100%;
        background: var(--green);
      }
    }

    .slick-prev,
    .slick-next {
      width: 15px;
      height: 15px;
    }
    .slick-prev {
      left: 15px;
      z-index: 3;
    }
    .slick-next {
      right: 15px;
      transform: rotate(180deg);
    }
  }
  .product-attributes {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    margin-top: 1.2rem;
    .product-attribute {
      display: flex;
      flex-direction: column;

      .attribute-name {
        color: var(--gray-text);
        font-size: 0.7rem;
      }
    }
  }
}

.select-buyer-session-modal,
.buyer-details-modal {
  .ant-modal-content {
    border-radius: 8px;
    margin: 0 1rem;

    .ant-modal-header {
      background: transparent;
      border-bottom: 0;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;

      .ant-modal-title {
        font-weight: 600;
      }
    }
    .ant-modal-body {
      padding-top: 0;
    }
  }
}
.buyer-details-modal {
  .ant-modal-body {
    padding-bottom: 1px;
  }
  .confirm-buyer-button {
    display: flex;
    justify-content: center;
    button {
      margin-top: 1rem;
      padding-left: 2rem;
      padding-right: 2rem;
      border-radius: 4px;
    }
  }
}
.select-buyer-session-modal {
  font-weight: 600;

  .ant-modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;

    .buyer-session-options {
      display: flex;
      flex-direction: column;
      align-self: stretch;
      gap: 1rem;
      * > {
        width: 100%;
        text-align: center;
        padding: 1rem 0;
        border-radius: 4px;

        &.selected {
          background-color: var(--light-green);
        }
        &:not(.selected) {
          background-color: var(--light-gray);
          color: var(--gray-text);
          cursor: pointer;
        }
      }
    }

    button {
      border-radius: 4px;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  }
}

.full-screen-loader {
  // z-index: 1000;
  background: var(--transparent-gray);
  display: flex;
  position: fixed;
  inset: 0;
  justify-content: center;
  align-items: center;
}
.view-products-card {
  background: #f1f7ff;
  padding: 1em;
  border-radius: 8px;
  .view-products-card-subtitle {
    margin-top: 12px;
  }
}

.total-buyers {
  background: var(--light-gray);
  padding: 1.5em 1em;
  margin-top: 0.75em;
  border-radius: 8px;
  .cta {
    padding: 0px;
    margin-top: 1.2em;
  }
}

.ant-message {
  z-index: 15000;
}

.ant-carousel .slick-dots li {
  background: grey !important;
}

@media (max-width: 600px) {
  .scan-more-button {
    position: fixed;
    right: 10px;
  }
}
@media (max-height: 600px) {
  .scan-more-button {
    display: hidden;
  }
}

@primary-color: #1D2624;@link-color: #278977;